import { gzip } from 'pako';
import { EScanModeTypes } from '../../static/scanModesMetaData';
import RestAPIHandler from './RestAPIHandler';
import {
  type IScanErrorResponse,
  type IGetToken,
  type IScanRequestIDImageBody,
  type IScanRequestMeterImageBody,
  type ITSWData,
  type ITSWRequestImageBody,
} from './types';

const RestAPI = {
  Token: {
    get: async () => {
      const url: string = '/api/token';
      return await RestAPIHandler.send<IGetToken>(url);
    },
  },
  CloudAPIv2: {
    getV2ScanData: async (
      imageData: string,
      endpointIDData: string,
      gcfSlugData: string,
      access_token: string,
      scanMode: EScanModeTypes,
    ) => {
      let url: string = process.env.REACT_APP_TSWAPI_BASEURL as string;
      switch (scanMode) {
        case EScanModeTypes.METER_EMEA:
          url = `${url}/meter/emea`;
          break;
        case EScanModeTypes.BARCODE:
          url = `${url}/barcode`;
          break;
        case EScanModeTypes.TSW:
          url = `${url}/tiresidewall`;
          break;
        default:
          url = `${url}/tiresidewall`;
          break;
      }
      const data: ITSWRequestImageBody = {
        image: imageData,
      };
      if (scanMode === EScanModeTypes.TSW) {
        if (endpointIDData) {
          data.endpointID = endpointIDData;
        }
        if (gcfSlugData) {
          data.gcfSlug = gcfSlugData;
        }
        if (process.env.REACT_APP_TSWAPI_CENG_FLAGS) {
          data.flags = process.env.REACT_APP_TSWAPI_CENG_FLAGS.split(',');
        }
      }
      const [body] = await Promise.all([gzip(JSON.stringify(data))]);
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip',
        'X-Referer': document.location.href,
      };
      if (new URL(url).host?.endsWith('anyline.com')) {
        headers.authorization = `Bearer ${access_token}`;
      } else {
        headers['x-forwarded-authorization'] = `Bearer ${access_token}`;
        headers['x-apigateway-api-userinfo'] = access_token.substring(
          0,
          access_token.indexOf('.'),
        );
      }

      const request: RequestInit = {
        body,
        headers,
        method: 'POST',
      };
      return await RestAPIHandler.send<ITSWData | IScanErrorResponse>(
        url,
        request,
      );
    },
  },
  Scan: {
    getScanData: async (scanMode: EScanModeTypes, imageData: string) => {
      const url: string = process.env.REACT_APP_SCAN_BASEURL as string;
      let data: IScanRequestMeterImageBody | IScanRequestIDImageBody;
      if (scanMode === EScanModeTypes.UNIVERSAL_ID) {
        data = {
          config: {
            viewPlugin: {
              plugin: {
                idPlugin: {
                  idConfig: {},
                },
              },
            },
          },
          // Store license in configurable file
          license: process.env.REACT_APP_LICENSE as string,
          blob: imageData,
        };
      } else {
        data = {
          config: {
            viewPlugin: {
              plugin: {
                meterPlugin: {
                  // Need to send ID based on the title clicked
                  scanMode,
                },
              },
            },
          },
          // Store license in configurable file
          license: process.env.REACT_APP_LICENSE as string,
          blob: imageData,
        };
      }
      const request = {
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-Referer': document.location.href,
        },
        method: 'POST',
      };
      return await RestAPIHandler.send(url, request);
    },
  },
};

export default RestAPI;
