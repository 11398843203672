import { Button, Grid, Typography } from '@mui/material';
import './Footer.css';
import React, { useContext } from 'react';
import { SidebarContext } from '../../contexts/SidebarContext';

const Footer = () => {
  const { footerData }: any = useContext(SidebarContext);

  const FooterLinks = () => {
    return (
      <>
        {footerData.map((item: any, i: number) => {
          return (
            <Grid className="footer-links" item xs={6} sm={3} md={2} key={i}>
              <a href={item.link} className="footer-link-head">
                {item.title}
              </a>
              <Typography variant="caption" className="footer-subtitle">
                {item.subTitles.map((sub: any, j: number) => {
                  return (
                    <div key={`${i}-${j}`}>
                      <a href={sub.link} className="footer-links">
                        {sub.title}
                      </a>
                    </div>
                  );
                })}
              </Typography>
            </Grid>
          );
        })}
      </>
    );
  };

  const SocialLinks = () => {
    return (
      <Grid item xs={12} sm={6} className="footer-social-links">
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/anylinecom/"
          >
            <img alt="" src="./fbIcon.png" className="footer-setting-icon" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/anyline/"
          >
            <img alt="" src="./inIcon.png" className="footer-setting-icon" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/anyline/"
          >
            <img alt="" src="./tweeticon.png" className="footer-setting-icon" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/c/Anylinecom"
          >
            <img alt="" src="./utubeicon.png" className="footer-setting-icon" />
          </a>
        </div>
      </Grid>
    );
  };

  const OtherLinks = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className={'footer-links-mobile'}
      >
        <Typography variant="caption" className="footer-subtitle">
          <div>
            <a
              href={'https://anyline.com/imprint-and-legal/'}
              className="footer-links-mobile"
            >
              Privacy Policy
            </a>
          </div>
        </Typography>
        <Typography variant="caption" className="footer-subtitle">
          <div>
            <a
              href={'https://anyline.com/imprint-and-legal/'}
              className="footer-links-mobile"
            >
              Imprint
            </a>
          </div>
        </Typography>
      </Grid>
    );
  };
  return (
    <>
      <div className="footer-container">
        <Grid container className="footer">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid className="footer-logo" item xs={6}>
              <a href="https://anyline.com/">
                <img alt="" src="./AIcon.png" className="footer-a-icon" />
              </a>
            </Grid>
          </Grid>
          <FooterLinks />
          <Grid item xs={2}></Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid className="footer-contacts" item xs={6}>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ float: 'left' }}
                href="https://anyline.com/request/contact"
              >
                <Button variant="text">
                  <img
                    alt=""
                    className="footer-contacts-img"
                    src="./group41.svg"
                  />
                  <span>GET IN CONTACT</span>
                </Button>
              </a>
            </Grid>
            <SocialLinks />
          </Grid>
          <OtherLinks />
        </Grid>
      </div>
    </>
  );
};

export default Footer;
