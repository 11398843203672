import {
  type ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import detectMetrics from '../../../../utils/opencv/detectMetrics';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import Modal from '../../../modal/Modal';
import PreviewImage from '../../../PreviewImage/PreviewImage';
import { isDesktop } from '../../../../utils/deviceDetection';
import init from '@anyline/anyline-guidance-sdk';
import { EScanModeTypes } from '../../../../static/scanModesMetaData';
import {
  type OnComplete,
  type OnPreProcessingChecksFailed,
} from '@anyline/anyline-guidance-sdk/dist/esm/camera/init';
import * as Sentry from '@sentry/react';
import blobToUint8Array from '../../../../utils/convertBlobToUint8Array';

interface IProps {
  handleCallback: (file: Blob) => Promise<void>;
}

export default function TakeOrUploadAPicture({ handleCallback }: IProps) {
  const {
    file,
    setFile,
    cookieConcent,
    setOpenModal,
    setCookieConcent,
    scanMode,
  }: any = useContext(SidebarContext);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const browseInputFileRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<Blob | undefined>(undefined);
  const [isBrowseSelected, setIsBrowseSelected] = useState<boolean>(false);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [imageMetrics, setImageMetrics] = useState<{
    isBlurDetected?: boolean;
    isEdgeDetected?: boolean;
    isContrastLow?: boolean;
  }>({});

  const [isLoadingMetrics, setIsLoadingMetrics] = useState<boolean>(true);

  const callbacksUGS = {
    onComplete: ({ blob }: OnComplete) => {
      setFile(blob);
      setImage(blob);
      setIsLoadingMetrics(true);
      setIsPreviewModalOpen(true);
    },
    onPreProcessingChecksFailed: async ({
      blob,
      message,
    }: OnPreProcessingChecksFailed) => {
      try {
        const uniqueId = Date.now();
        const uint8Array = await blobToUint8Array(blob);
        Sentry.getCurrentScope().addAttachment({
          filename: `failedPrecheckImg-${uniqueId}.png`,
          data: uint8Array,
        });
        Sentry.captureMessage(`${message}-${uniqueId}`);
        Sentry.getCurrentScope().clearAttachments();
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  };

  const handleOnProceed = async () => {
    if (!cookieConcent) {
      setOpenModal(true);
      setCookieConcent(true);
      return;
    }
    await handleCallback(file);
  };

  const processFile = async () => {
    if (!image) return;
    const reader = new FileReader();

    // convert img to dataurl so it can be processed with opencv
    reader.readAsDataURL(image);

    reader.onload = async function (event) {
      if (typeof event.target?.result === 'string') {
        const fileContent = event.target.result;
        try {
          const { isBlurDetected, isEdgeDetected, isContrastLow } =
            await detectMetrics(fileContent);

          const isImageClean =
            !isBlurDetected && isEdgeDetected && !isContrastLow;

          if (isImageClean) {
            // if image is "clean" (i.e it passed all the checks, close the modal and proceed to call the API)
            setIsPreviewModalOpen(false);
            await handleOnProceed();
            return;
          }

          // if the image is not "clean", show the failed checks
          setImageMetrics({ isBlurDetected, isEdgeDetected, isContrastLow });
          setIsLoadingMetrics(false);
        } catch (err) {
          console.error(err);
          // if open cv failed to load, proceed without processing the image
          setIsPreviewModalOpen(false);
          setIsLoadingMetrics(false);
          await handleOnProceed();
        }
      }
    };
  };

  const handleImageInputMobileTSW = async (
    _e: React.MouseEvent<HTMLInputElement>,
    isBrowse: boolean,
  ) => {
    setIsBrowseSelected(isBrowse);
    try {
      init(
        {
          onboardingInstructions: {
            timesShown: 2,
          },
        },
        callbacksUGS,
      );

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('isUGS', 'true');
      window.history.replaceState({}, '', `?${searchParams.toString()}`);
    } catch (err) {
      console.error('Error retrieving image from user guidance screen', err);
    }
  };

  const handleImageInput = async (
    e: ChangeEvent<HTMLInputElement>,
    isBrowse: boolean,
  ): Promise<void> => {
    setIsBrowseSelected(isBrowse);
    const file = e.target.files?.[0];
    if (!file) return;
    setIsLoadingMetrics(true);
    setIsPreviewModalOpen(true);
    setFile(file);
    setImage(file);
  };

  const handleOnNewPicture = () => {
    setIsPreviewModalOpen(false);
    if (isBrowseSelected || isDesktop()) {
      browseInputFileRef.current?.click();
      return;
    }
    inputFileRef.current?.click();
  };

  useEffect(() => {
    if (!image) return;
    if (scanMode !== EScanModeTypes.TSW) {
      processFile();
    } else {
      setIsPreviewModalOpen(false);
      handleOnProceed();
    }
  }, [image]);
  return (
    <>
      <Modal open={isPreviewModalOpen}>
        <PreviewImage
          file={file}
          isLoadingMetrics={isLoadingMetrics}
          imageMetrics={imageMetrics}
          onNewPicture={() => {
            handleOnNewPicture();
          }}
          onProceed={async () => {
            setIsPreviewModalOpen(false);
            await handleOnProceed();
          }}
        />
      </Modal>
      {/* User Guidance screen for TireSideWall */}
      {!isDesktop() &&
        scanMode === EScanModeTypes.TSW &&
        process.env.REACT_APP_USE_USER_GUIDANCE_TSW === 'true' && (
          <Grid item xs={12}>
            <input
              ref={inputFileRef}
              accept="image/*"
              id="imageInput"
              capture="environment"
              type="button"
              onClick={async (e: React.MouseEvent<HTMLInputElement>) => {
                await handleImageInputMobileTSW(e, false);
              }}
              hidden
            />
            <label htmlFor="imageInput" className="imgLabelWithIcon">
              <div className="imageLabelIconWrapper">
                <CameraAltIcon />
              </div>
              <div>
                <span className="uploadButton">TAKE A PICTURE</span>
              </div>
            </label>
          </Grid>
        )}
      {/* Direct camera access only for non-desktop devices */}
      {!isDesktop() &&
        (scanMode !== EScanModeTypes.TSW ||
          process.env.REACT_APP_USE_USER_GUIDANCE_TSW === 'false') && (
          <Grid item xs={12}>
            <input
              ref={inputFileRef}
              accept="image/*"
              id="imageInput"
              capture="environment"
              type="file"
              onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                await handleImageInput(e, true);
              }}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.currentTarget.value = '';
              }}
              hidden
            />
            <label htmlFor="imageInput" className="imgLabelWithIcon">
              <div className="imageLabelIconWrapper">
                <CameraAltIcon />
              </div>
              <div>
                <span className="uploadButton">TAKE A PICTURE</span>
              </div>
            </label>
          </Grid>
        )}
      {/* Gallery plus camera access */}
      <Grid item xs={12}>
        <input
          ref={browseInputFileRef}
          accept="image/*"
          id="icon-button-file"
          type="file"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            await handleImageInput(e, true);
          }}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => {
            e.currentTarget.value = '';
          }}
          hidden
        />
        <label htmlFor="icon-button-file" className="imgLabel">
          <img src="/upload.svg" alt="uploadIcon" className="uploadIcon" />
          <span className="uploadButton">BROWSE AND UPLOAD IMAGE</span>
        </label>
        <Typography variant="caption" className="sidebar-imgInfo">
          Only (.jpg), (.jpeg) and (.png) formats are allowed.
        </Typography>
      </Grid>
    </>
  );
}
