export default function computeVarianceOrContrast(
  mat: any,
  isVariance: boolean,
) {
  const mean = new cv.Mat();
  const stdDev = new cv.Mat();
  cv.meanStdDev(mat, mean, stdDev);
  const result = isVariance
    ? Math.pow(stdDev.data64F[0], 2)
    : stdDev.data64F[0];
  mean.delete();
  stdDev.delete();
  return result;
}
