import { useContext, useCallback } from 'react';
import { SidebarContext } from '../../../contexts/SidebarContext';
import useLazyRequest from '../../../hooks/useLazyRequest';
import RestAPI from '../../../services/RequestHandler/RestAPIList';
import { EScanModeTypes } from '../../../static/scanModesMetaData';
import {
  getTokenFromLocalStorage,
  isTokenExpired,
  setTokenToLocalStorage,
} from '../utils/tokenUtil';

export const useProcessImage = () => {
  const { call: getToken } = useLazyRequest(RestAPI.Token.get);
  const { call: getV2ScanData } = useLazyRequest(
    RestAPI.CloudAPIv2.getV2ScanData,
  );
  const { call: getScanData } = useLazyRequest(RestAPI.Scan.getScanData);

  const {
    scanMode,
    setError,
    endpointID,
    gcfSlug,
    setCurrentProgress,
    selectedCard,
    setDataApi,
    setActiveStep,
  }: any = useContext(SidebarContext);

  const processImage = useCallback(
    async (imageData: string): Promise<void> => {
      const onAPIErrorResponse = () => {
        setError(true);
        setActiveStep(4);
        setCurrentProgress(1);
      };

      const onAPISuccessResponse = (
        timeout1: NodeJS.Timeout,
        timeout2: NodeJS.Timeout,
        data: any,
        scanErrorFeedback: boolean = false,
      ) => {
        setError(scanErrorFeedback);
        setDataApi(data);
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        setCurrentProgress(4);
        setTimeout(() => {
          setActiveStep(4);
          setCurrentProgress(1);
        }, 1000);
      };

      const scrollToView = () => {
        const width = window.innerWidth;
        if (width < 600) {
          const access = document.getElementById('para');
          if (access != null) {
            access.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      };

      const handleV2ScanData = async (
        timeout1: NodeJS.Timeout,
        timeout2: NodeJS.Timeout,
        imageData: string,
        endpointID: string,
        gcfSlug: string,
      ) => {
        try {
          // get token
          let token = getTokenFromLocalStorage();
          if (!token || isTokenExpired(token) || token === 'undefined') {
            ({ access_token: token } = await getToken());
            if (token) {
              setTokenToLocalStorage(token);
            } else {
              onAPIErrorResponse();
            }
          }
          if (token) {
            // call the CloudAPI V2 API with image and token
            const response = await getV2ScanData(
              imageData,
              endpointID,
              gcfSlug,
              token,
              selectedCard.scanMode,
            );
            if ('message' in response) {
              onAPISuccessResponse(timeout1, timeout2, response, true);
            } else {
              onAPISuccessResponse(timeout1, timeout2, response);
            }
          }
        } catch (e) {
          onAPIErrorResponse();
        }
      };

      const handleScanData = async (
        timeout1: NodeJS.Timeout,
        timeout2: NodeJS.Timeout,
        imageData: string,
      ) => {
        try {
          const result = await getScanData(scanMode, imageData);
          if (result) {
            onAPISuccessResponse(timeout1, timeout2, result);
          }
        } catch (e) {
          onAPIErrorResponse();
        }
      };

      scrollToView();
      setError(false);
      const timeout1 = setTimeout(() => {
        setCurrentProgress(2);
      }, 4000);

      const timeout2 = setTimeout(() => {
        setCurrentProgress(3);
      }, 6000);

      if (
        selectedCard.scanMode === EScanModeTypes.TSW ||
        selectedCard.scanMode === EScanModeTypes.BARCODE ||
        selectedCard.scanMode === EScanModeTypes.METER_EMEA
      ) {
        await handleV2ScanData(
          timeout1,
          timeout2,
          imageData,
          endpointID,
          gcfSlug,
        );
        return;
      }

      await handleScanData(timeout1, timeout2, imageData);
    },
    [
      scanMode,
      selectedCard,
      setError,
      setCurrentProgress,
      setDataApi,
      setActiveStep,
      endpointID,
      gcfSlug,
    ],
  );

  return { processImage };
};
