import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import './App.css';
import Layout from './components/Layout';
import MainContent from './components/MainContent/MainContent';
import Navbar from './components/Navbar';
import Footer from './components/footer/Footer';
import Sidebar from './components/sidebar/Sidebar';
import { SidebarContext } from './contexts/SidebarContext';
import footerLinksMetaData, {
  type IFooterLinksMetaData,
} from './static/footerLinksMetaData';
import './styles/Common.scss';

function App() {
  const [footerData, setFooterData] =
    useState<IFooterLinksMetaData[]>(footerLinksMetaData);
  const [isClicked, setIsClicked] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [modalSession, setModalSession] = useState(0);
  const [dataApi, setDataApi] = useState('');
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [file, setFile] = useState();
  const [cardId, setCardId] = useState();
  const [style, setStyle] = useState('cardStyle');
  const [error, setError] = useState(false);
  const [scanMode, setScanMode] = useState('');
  const [currentProgress, setCurrentProgress] = useState(1);
  const [cookieConcent, setCookieConcent] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [endpointID, setEndpointID] = useState('');
  const [gcfSlug, setGcfSlug] = useState('');

  return (
    <div className="App">
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <SidebarContext.Provider
          value={{
            dataApi,
            setDataApi,
            isClicked,
            setIsClicked,
            style,
            setStyle,
            cardId,
            setCardId,
            file,
            setFile,
            activeStep,
            setActiveStep,
            openModal,
            setOpenModal,
            modalSession,
            setModalSession,
            uploading,
            setUploading,
            previewImage,
            setPreviewImage,
            footerData,
            setFooterData,
            error,
            setError,
            scanMode,
            setScanMode,
            currentProgress,
            setCurrentProgress,
            cookieConcent,
            setCookieConcent,
            selectedCard,
            setSelectedCard,
            endpointID,
            setEndpointID,
            gcfSlug,
            setGcfSlug,
          }}
        >
          <Navbar />
          <Layout sidebar={<Sidebar />} mainContent={<MainContent />} />
          <Footer />
        </SidebarContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
