import css from './ErrorBanner.module.css';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  file: any;
  errorMsg: string;
}

export default function ErrorBannerTSW({ file, errorMsg }: IProps) {
  const [fileContent, setFileContent] = useState<any | undefined>(undefined);

  const getFileContent = () => {
    const reader = new FileReader();

    try {
      // convert img to dataurl so it can be processed with opencv
      reader.readAsDataURL(file);

      reader.onload = async function (event) {
        if (typeof event.target?.result === 'string') {
          const fileContent = event.target.result;
          setFileContent(fileContent);
        }
      };
    } catch {
      setFileContent(undefined);
    }
  };

  useEffect(() => {
    getFileContent();
  }, []);

  return (
    <div className={css.wrapper}>
      <div className={css.descriptionWrapper}>
        <h1 className={css.errorTitle}>
          Oops! {errorMsg || 'Something went wrong'}
        </h1>
        <p>
          Please make sure the image has good and consistent lighting
          conditions, all information is readable, and the photo is taken as
          parallel to the tire as possible.
        </p>
      </div>
      <div>
        {!fileContent ? (
          <div className={css.inputImagePlaceholder}>Loading image...</div>
        ) : (
          <img className={css.inputImage} src={fileContent} alt="Your Image" />
        )}
      </div>
      <div className={css.buttonWrapper}>
        <button
          onClick={() => {
            window.location.reload();
          }}
          className={css.primaryButton}
        >
          <Typography variant="subtitle2">
            <strong>Try again</strong>
          </Typography>
        </button>
      </div>
    </div>
  );
}
