import React, { Suspense } from 'react';
import LazyCheckmark from './Checkmark/LazyCheckmark';
import LazyLightBulb from './LightBulb/LightBulb';
import LazyPerson from './Person/LazyPerson';
import LazyThunder from './Thunder/LazyThunder';

interface IconList {
  Checkmark: React.FC<{}>;
  LightBulb: React.FC<{}>;
  Person: React.FC<{}>;
  Thunder: React.FC<{}>;
}

const Icon: React.FC & IconList = () => null;

// eslint-disable-next-line react/display-name
Icon.Checkmark = () => (
  <Suspense fallback={null}>
    <LazyCheckmark />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.LightBulb = () => (
  <Suspense fallback={null}>
    <LazyLightBulb />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Person = () => (
  <Suspense fallback={null}>
    <LazyPerson />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Thunder = () => (
  <Suspense fallback={null}>
    <LazyThunder />
  </Suspense>
);
export default Icon;
